import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons'; 
import { faGamepad, faMountain, faCrosshairs, faPuzzlePiece, faFutbol } from '@fortawesome/free-solid-svg-icons';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import { Offcanvas } from 'bootstrap'; 
import '../Styles/Navbar.css';

const Navbar = () => {
 
  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
  
    // Close the offcanvas
    const offCanvasElement = document.getElementById('offcanvasNavbar');
    const bsOffCanvas = Offcanvas.getInstance(offCanvasElement); // Get the instance of your offcanvas
    if (bsOffCanvas) {
      bsOffCanvas.hide(); // Hide the offcanvas
    }
  
    // Manually remove the backdrop if it exists
    const backdrop = document.querySelector('.offcanvas-backdrop');
    if (backdrop) {
      backdrop.remove();
    }
  
    // Scroll to the section
    const section = document.querySelector(`#${sectionId}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  
  
    return (
        <>
      <nav className="navbar navbar-expand-lg navbar-light navbg pt-0 pb-0">
        <div className="container-fluid">
          <Link className="navbar-brand ms-2" to="/"><img src="./images/logo_3-.png" alt="" width="100" height="95" /></Link>
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <i className="fas fa-bars"></i>
          </button> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
           <FontAwesomeIcon icon={faBarsStaggered} /> {/* Use a more stylish icon like faBarsStaggered */}
          </button>
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Games Chakra</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
      <ul className="navbar-nav m-auto nav_ul">
      <li className="nav-item">
      <a href="#arcade-section" className="nav-link arcade" onClick={(e) => scrollToSection(e, 'arcade-section')}>   
      <span><FontAwesomeIcon icon={faGamepad} /> </span> Arcade
      </a>
            </li>
            <li className="nav-item">
            <a href="#shooting-section" className="nav-link shooting" onClick={(e) => scrollToSection(e, 'shooting-section')}>
            <span><FontAwesomeIcon icon={faCrosshairs} /> </span> Shooting
            </a>
            </li>
            <li className="nav-item">
            <a href="#puzzle-section" className="nav-link puzzle" onClick={(e) => scrollToSection(e, 'puzzle-section')}>
            <span><FontAwesomeIcon icon={faPuzzlePiece} /> </span> Puzzle
            </a>
            </li>
            <li className="nav-item">
            <a href="#adventure-section" className="nav-link adventure" onClick={(e) => scrollToSection(e, 'adventure-section')}>
            <span> <FontAwesomeIcon icon={faMountain} /> </span> Adventure
            </a>
            </li>
            <li className="nav-item">
            <a href="#sports-section" className="nav-link sports" onClick={(e) => scrollToSection(e, 'sports-section')}>
           <span> <FontAwesomeIcon icon={faFutbol} /> </span>Sports
            </a>
            </li>
       
       
      </ul>
      <div className="nav-item me-3 dev hidden-sm">
      <Link className="nav-link"  to="/about" rel="noopener noreferrer">About Us</Link>
      </div>
      <div className="nav-item me-3 dev hidden-md">
      <Link className="nav-link hidden-sm"  to="/devportal" rel="noopener noreferrer">DevPortal</Link>
      </div>
      </div>
    </div>
  </div>
</nav>



           
        </>
    )
}

export default Navbar


