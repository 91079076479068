// import React, { useState, useEffect } from 'react';
import Games from './Games';
import RecentlyPlayed from './RecentlyPlayed';
import LatestGames from './LatestGames';
// import AdComponent from './AdComponent';

export default function Home() {


  return (
    <>
      <LatestGames />
      <RecentlyPlayed />
      <Games />
    </>
  );
}
