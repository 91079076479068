// LatestGames.js
import React, { useState, useEffect } from 'react';
import '../Styles/LatestGames.css';
// Define a separate array for the featured games with different images
const featuredGames = [
  { imageUrl: '/images/Looniebirdsgpt.webp', link: 'https://gameschakra.com/testing/loonieBirds/v6/' },
  { imageUrl: '/images/Hoppingcrownsgpt5.webp', link: 'https://gameschakra.com/testing/hopping-crowns/v7/' },
  { imageUrl: '/images/Tictactoe.webp', link: 'https://gameschakra.com/testing/tic-tac-toe/v7/' },
  { imageUrl: '/images/waverun.webp', link: 'https://gameschakra.com/testing/waverun/v1/' },
  { imageUrl: '/images/90degreesgpt.webp', link: 'https://gameschakra.com/testing/90%20Degrees/' }
];
const games = [
  { imageUrl: '/images/numbersnake2.webp', link: 'https://gameschakra.com/testing/NumberSnake/v2/' },
  { imageUrl: '/images/colorup2.webp', link: 'https://gameschakra.com/testing/colorUp/v1/' },
  { imageUrl: '/images/bubblesort.webp', link: 'https://gameschakra.com/testing/BubbleSort/v1/' },
  { imageUrl: '/images/waverun.webp', link: 'https://gameschakra.com/testing/waverun/v1/' },
  { imageUrl: '/images/colordrop.webp', link: 'https://gameschakra.com/testing/colorDrop/v1/' },
  { imageUrl: '/images/Tictactoe.webp', link: 'https://gameschakra.com/testing/tic-tac-toe/v7/' },

  // { imageUrl: '/images/90degreesgpt2.webp', link: 'https://gameschakra.com/testing/90%20Degrees/' },
  // { imageUrl: '/images/Hoppingcrownsgpt5.webp', link: 'https://gameschakra.com/testing/hopping-crowns/v7/' },
  // { imageUrl: '/images/Starblastergpt.webp', link: 'https://gameschakra.com/testing/star%20Blaster/' },
  // { imageUrl: '/images/Looniebirdsgpt2.webp', link: 'https://gameschakra.com/testing/loonieBirds/v6/' },
  // { imageUrl: '/images/Basketgpt3.webp', link: 'http://gameschakra.com/testing/basket/' }
];

const LatestGames = () => {
  // const [current, setCurrent] = useState(0);
  const [featuredCurrent, setFeaturedCurrent] = useState(0); // state to keep track of the current featured game
  const [key, setKey] = useState(Math.random()); // add a key state

  useEffect(() => {
    const interval = setInterval(() => {
      // setCurrent((current) => (current + 1) % games.length);
      setFeaturedCurrent((featuredCurrent) => (featuredCurrent + 1) % featuredGames.length); // increment the featured game index
      setKey(Math.random());
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // Function to force reflow/repaint
  const forceReflow = (node) => {
    if (node) {
      return node.offsetHeight; // accessing offsetHeight will trigger reflow
    }
  };
  return (
    <div className="latest-games-wrapper">
     <div className="featured-game">
         {/* add the key prop to force re-render */}
         <a href={featuredGames[featuredCurrent].link} target="_blank" rel="noopener noreferrer">
         <img
            src={featuredGames[featuredCurrent].imageUrl}
            alt={featuredGames[featuredCurrent].name}
            className="fade-effect"
            key={key}
            onLoad={({ target }) => forceReflow(target)}
          />
        </a>    
      </div>
      <div className="latest-games-list">
        <h2>Latest Games</h2>
        <div className="games-thumbnails">
          {games.map((game, index) => (
            <a key={index} href={game.link} rel="noopener noreferrer" className="game-thumbnail">
              <img src={game.imageUrl} alt={game.name} />
              <h4>{game.name}</h4>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestGames;
