import React, { useEffect, useState } from 'react';
import { GameCard } from './Games'; 
import '../Styles/RecentlyPlayed.css';

const RecentlyPlayed = () => {
  const [recentlyPlayedGames, setRecentlyPlayedGames] = useState([]);

  useEffect(() => {
    const playedGames = JSON.parse(localStorage.getItem('recentlyPlayedGames')) || [];
    setRecentlyPlayedGames(playedGames);
  }, []);

  // Render the heading and games only if there are recently played games
  return (
    <div className="recently-played">
      {recentlyPlayedGames.length > 0 && ( // Conditional rendering based on the length of the array
        <>
          <h2>Recently Played</h2>
          <div className="row">
            {recentlyPlayedGames.map((game, index) => {
              return <GameCard key={`${game.name}-${index}`} game={game} className="card" />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default RecentlyPlayed;
