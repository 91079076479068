// ContactUs.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../Styles/ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <div className="contact-content">
        <div className="contact-details">
          <div className="contact-item">
            <FontAwesomeIcon icon={faPhone} size="2x" />
            <p className="contact-text">+91 9953105778</p>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
            <p className="contact-text">madishanstudios@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
