import React, { useState, useEffect } from 'react';
import '../Styles/Games.css';
import 'animate.css';
import games from './Data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMountain, faCrosshairs, faPuzzlePiece, faFutbol } from '@fortawesome/free-solid-svg-icons';
import AdComponent from './AdComponent';

export const GameCard = ({ game }) => {
  const [showAdModal, setShowAdModal] = useState(false);

  const handleGameClick = (e) => {
    e.preventDefault();
    setShowAdModal(true); // Show the ad modal when a game is clicked
  };

  useEffect(() => {
    if (showAdModal) {
      const timer = setTimeout(() => {
        window.location.href = game.link; // Redirect to game after 10 seconds
      }, 10000);

      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, [showAdModal, game.link]);

  const handleCloseAd = () => {
    setShowAdModal(false); // Allow the modal to be closed
  };

  return (
    <>
      {showAdModal && (
        <div className="ad-modal">
          <div className="ad-modal-content">
            <button className="ad-modal-close" onClick={handleCloseAd}>
              &times;
            </button> {/* Close button */}
            <AdComponent onAdLoadFail={handleCloseAd} onCloseAd={handleCloseAd} /> {/* Ad component with close handler */}
          </div>
        </div>
      )}
      <div className="col-6 col-md-4 col-lg-2 mb-4">
        <a href={game.link} onClick={handleGameClick}>
          <div className="card h-100">
            <img src={game.imageUrl} className="card-img-top" alt={game.name} />
            <div className="card-body">
              <h6 className="card-title">{game.name}</h6>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

const Games = () => {
  const headings = ["Arcade", "Shooting", "Puzzle", "Adventure", "Sports"];
  const icons = {
    Arcade: faGamepad,
    Shooting: faCrosshairs,
    Puzzle: faPuzzlePiece,
    Adventure: faMountain,
    Sports: faFutbol
  };

  return (
    <div className="container mt-4">
      {headings.map((heading, rowIndex) => (
        <div key={rowIndex} className="game-section">
          <h4 className="mt-4">
            <FontAwesomeIcon icon={icons[heading]} /> {heading}
          </h4>
          <div className="row row-cols-1 row-cols-md-6 g-4">
            {games.slice(rowIndex * 6, rowIndex * 6 + 6).map((game, index) => (
              <GameCard key={index} game={game} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Games;
