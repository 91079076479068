import React from 'react';
import '../Styles/About.css';

function About() {
  return (
    <div className="about-container">
      <h1>Hi there, gaming fam!</h1>
      <p>The next generation of gaming is finally here to reign the gaming world.</p>

      <p>Creative developers at GAMESCHAKRA wanted to transform the gaming world with utter creativity, user-friendly features and fun, so we created an integrated, adaptable, and innovative gaming platform inclusive for one and all.</p>

      <p>Stuck on what to play? Well, turn on your gaming gears and get ready to evolve your gaming experience.</p>

      <p>Our games are designed to stay on the cutting edge of innovation and advanced technology is our magic!</p>

      <p>Play with anyone, anywhere, on any device, it's that easy!</p>

      <p>Gameschakra has a diverse range of games developed exclusively for every age group.</p>

      <p>Gamify your day today and start playing. Our games are free. Let's explore the world of adventures.</p>

      <p>We truly value our community and strive to make your gaming experience fun-filled. Please reach out with any queries or feedbacks. We'd be more than happy to interact.</p>

      <h2>It's your time to WIN IT ALL.</h2>
    </div>
  );
}

export default About;
