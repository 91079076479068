import React from 'react';
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import '../Styles/Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-icons">
        <FaFacebookF />
        <FaInstagram />
        
        <FaYoutube />
        <FaLinkedinIn />
      </div>
      <div className="footer-brand">
        <p>Copyright © 2023 GamesChakra</p>
      </div>
      <div className="footer-links">
        <Link to="/privacypolicy" rel="noopener noreferrer"> Privacy Policy&nbsp;</Link>
        <Link to="/contactus" rel="noopener noreferrer">ContactUs&nbsp;</Link>
        <Link to="/termsconditions" rel="noopener noreferrer">Terms&Conditions </Link>
      </div>
    </div>
  );
};

export default Footer;
