import React, { useState, useEffect } from 'react';
import '../Styles/ScrollToTop.css';
import { FaArrowCircleUp } from 'react-icons/fa';  


const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (typeof window !== 'undefined' && window.scrollY > 500) {
        setIsVisible(true);
    } else {
        setIsVisible(false);
    }
};


  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className={`scroll-to-top ${isVisible ? 'show' : ''}`}>
      <div onClick={scrollToTop}>
        <FaArrowCircleUp size={30} />
      </div>
    </div>
  );
  
}

export default ScrollToTop;
