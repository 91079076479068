import React from 'react'
import '../Styles/DevPortal.css';
function DevPortal() {
  return (
    <div className="coming-soon-container">
    <span className="coming-soon-text">COMING</span>
    <span className="coming-soon-text">SOON</span>
    </div>
  )
}

export default DevPortal
