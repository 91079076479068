import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './components/Home';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Games from "./components/Games";
import About from './components/About';
import Privacypolicy from './components/Privacypolicy';
import ContactUs from './components/ContactUs';
import Termsconditions from './components/Termsconditions';
import DevPortal from './components/DevPortal';
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorBoundary from './components/ErrorBoundary';
import AdComponent from './components/AdComponent';

function App() {
  const [showAdModal, setShowAdModal] = useState(true);
  const [selectedGame, setSelectedGame] = useState(null);

  // Handle the initial site load ad
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAdModal(false); // Automatically close modal after 10 seconds
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, []);

  const handleGameClick = (game) => {
    setSelectedGame(game);
    setShowAdModal(true);
  };

  const handleCloseAd = () => {
    setShowAdModal(false);
  };

  // Handle ad modal visibility after clicking a game thumbnail
  useEffect(() => {
    if (showAdModal && selectedGame) {
      const timer = setTimeout(() => {
        window.location.href = selectedGame.link; // Redirect to game after 10 seconds
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [showAdModal, selectedGame]);

  return (
    <BrowserRouter>
      {showAdModal && (
        <div className="ad-modal">
          <div className="ad-modal-content">
            <AdComponent 
              onAdLoadFail={() => setShowAdModal(false)} 
              onCloseAd={handleCloseAd}  // Pass the close button handler
            />
          </div>
        </div>
      )}
      <Navbar />
      <ScrollToTop />
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/games" element={<Games onGameClick={handleGameClick} />} /> {/* Ensure the onGameClick prop is passed */}
          <Route path="/about" element={<About />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/termsconditions" element={<Termsconditions />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/devportal" element={<DevPortal />} />
        </Routes>
      </ErrorBoundary>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
