import React, { useEffect, useRef, useState } from 'react';

const AdComponent = ({ onAdLoadFail, onCloseAd }) => {
  const adRef = useRef(null);
  const [showCloseButton, setShowCloseButton] = useState(false); // For delay in showing the close button

  useEffect(() => {
    const adElement = adRef.current;

    const checkSizeAndLoadAd = () => {
      if (adElement && adElement.offsetWidth > 0) {
        try {
          window.adsbygoogle.push({});
        } catch (e) {
          console.error("Adsbygoogle error: ", e.message);
          onAdLoadFail(); // Close modal if there's an error
        }
      } else {
        console.error("Ad container has no valid width");
        onAdLoadFail(); // Close the modal if there's no valid width
      }
    };

    // Check size and load ad
    checkSizeAndLoadAd();

    // Show the close button after a small delay
    const closeButtonDelay = setTimeout(() => {
      setShowCloseButton(true); // Show the close button after 1 second
    }, 1000);

    return () => clearTimeout(closeButtonDelay); // Clean up timer
  }, [onAdLoadFail]);

  return (
    <div style={{ position: 'relative', minWidth: '300px', minHeight: '250px', width: '100%' }}>
      {showCloseButton && (
        <button
          className="ad-modal-close"
          onClick={onCloseAd}
        >
          &times;
        </button>
      )}
      <ins
        className="adsbygoogle"
        ref={adRef}
        style={{ display: 'block', width: '100%', height: '100%' }}
        data-ad-client="ca-pub-2067900913632539"
        data-ad-slot="9149419367"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default AdComponent;
